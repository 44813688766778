<script>
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { pieChart } from "./data-apex";
/**
 * Informasi Component
 */
export default {
    page: {
        title: "Informasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        // PageHeader,
        Datatable,
        Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columnsSkema = [
            { width: "50px", label: "No", name: "-" },
            { width: "auto", label: "Kode", name: "nama_skema_sertifikasi" },
            { width: "auto", label: "Nama Skema", name: "nama_skema_sertifikasi" },
            { width: "auto", label: "Jumlah UK", name: "jumlah_uk" },
        ];

        // cek sorting
        columnsSkema.forEach((columnSkema) => {
            sortOrders[columnSkema.name] = -1;
        });

        let columnsJenisTuk = [
            { width: "50px", label: "No", name: "-" },
            { width: "auto", label: "Kategori", name: "nama_skema_sertifikasi" },
            { width: "auto", label: "Jumlah TUK", name: "b_nama" },
        ];

        // cek sorting
        columnsJenisTuk.forEach((columnJenisTuk) => {
            sortOrders[columnJenisTuk.name] = -1;
        });

        return {
            title: "Informasi",
            pieChart: pieChart,
            jumlahAsesor: {
                series: [20, 10],
                chartOptions: {
                    labels: ["Asesor Aktif", "Asesor Non Aktif"],
                    colors: ["#34c38f", "#556ee6"],
                    legend: {
                        show: true,
                        position: "bottom",
                        horizontalAlign: "center",
                        verticalAlign: "middle",
                        floating: false,
                        fontSize: "14px",
                        offsetX: 0,
                    },
                    responsive: [
                        {
                            breakpoint: 600,
                            options: {
                                chart: {
                                    height: 240,
                                },
                                legend: {
                                    show: false,
                                },
                            },
                        },
                    ],
                },
            },
            HasilAsesor: [
                { nama: "Asesor Aktif", jumlah: 0 },
                { nama: "Asesor Non Aktif", jumlah: 0 },
            ],

            HasilPelaksanaAsesmen: [
                { kategori: "Biological Product", subkategori: "Vaksin", jumlah: 20 },
                { kategori: "Herbal Product", subkategori: "Vaksin", jumlah: 0 },
                { kategori: "Pharmaceutical Product", subkategori: "Vaksin", jumlah: 0 },
            ],
            jumlahPelaksanaAsesmen: {
                series: [
                    {
                        name: "Biological Product",
                        data: [201],
                    },
                    {
                        name: "Herbal Product",
                        data: [0],
                    },
                    {
                        name: "Pharmaceutical Product",
                        data: [0],
                    },
                ],
                chartOptions: {
                    toolbar: {
                        show: false,
                    },
                    legend: { show: true },
                    labels: ["Biological Product", "Herbal Product", "Pharmaceutical Product"],
                    responsive: [
                        {
                            breakpoint: 600,
                            options: {
                                chart: {
                                    height: 240,
                                },
                                legend: {
                                    show: false,
                                },
                            },
                        },
                    ],
                },
            },
            HasilTuk: [
                { namaKategori: "Sewaktu(Portofolio)", jumlah: 20 },
                { namaKategori: "Tempat Kerja(Observasi)", jumlah: 10 },
                { namaKategori: "Mandiri", jumlah: 10 },
            ],
            jumlahTuk: {
                series: [0,23,25],
                chartOptions: {
                    labels: ["Mandiri","Sewaktu","Tempat Kerja"],	
                    colors: ["#34c38f", "#556ee6", "#f46a6a"],
                    legend: {
                        show: true,
                        position: "bottom",
                        horizontalAlign: "center",
                        verticalAlign: "middle",
                        floating: false,
                        fontSize: "14px",
                        offsetX: 0,
                    },
                    responsive: [
                        {
                            breakpoint: 600,
                            options: {
                                chart: {
                                    height: 240,
                                },
                                legend: {
                                    show: false,
                                },
                            },
                        },
                    ],
                },
            },
            id: '99caddf0-3de8-42d7-a254-a3f02f9a5e16',
            HasilPersyaratanDasar: [{ namaPersyaratan: "Membawa surat rekomendasi" }, { namaPersyaratan: "Mengisi Formulir Pendaftaran" }, { namaPersyaratan: "Melampirkan Fotokopi KTP" }, { namaPersyaratan: "Menyerahkan Riwayat Hidup (CV)" }, { namaPersyaratan: "Mengisi Surat Pernyataan" }, { namaPersyaratan: "Membayar Biaya Pendaftaran" }, { namaPersyaratan: "Memiliki Sertifikat Pelatihan Terkait:" }],
            loadingTable: true,
            loadingTableJenisTuk: true,
            table_dataSkema: [],
            table_dataJenisTuk: [],
            columnsSkema: columnsSkema,
            columnsJenisTuk: columnsJenisTuk,
            sortKey: "nama_skema_sertifikasi", // set default column
            sortOrders: sortOrders,
            currentTablePage: "",

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
            JumlahPelaksaanAsesmen: null,
            nama_halaman: "",
            banner_path: "",
            banner_full: "",
            konten: "",
            gambar_halaman_path: "",
            gambar_halaman_full: "",
            kategori: "",
            meta_content: [],
            berlaku : [],
            tidak_berlaku : [],
            countBerlaku : 0,
            countTidakBerlaku : 0,

        };
    },
    mounted() {
        if (localStorage.getItem("reloaded")) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem("reloaded");
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem("reloaded", "1");
            location.reload();
        }
        let self = this;
        self.getDataHalaman();
        self.getJenisTUk();
        self.getPelaksanaanAsesmen();
        self.getAsesor();
    },
    methods: {
        getDataHalaman(){
            let self = this;
        // get data halaman berdasarkan id
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-website/" + self.id,
                params: {},
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var data_fix = response_data_fix;
                        self.nama_halaman = data_fix.nama_halaman;
                        self.banner_path = data_fix.banner;
                        self.banner_full = process.env.VUE_APP_BACKEND_URL + data_fix.banner;
                        self.konten = data_fix.konten;
                        self.gambar_halaman_path = data_fix.gambar_halaman;
                        self.gambar_halaman_full = process.env.VUE_APP_BACKEND_URL + data_fix.gambar_halaman;
                        self.kategori = data_fix.kategori?.nama_kategori_halaman;
                        self.meta_content = JSON.parse(data_fix.meta_content);
                        Swal.close();
                        self.getSkema();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        getSkema(url = process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-informasi/jumlah-skema") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;
            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    if (response_data.meta.code == 200) {
                        self.table_dataSkema = response_data.data.list;
                        console.log(self.table_dataSkema);
                        self.configPagination(response_data.data.pagination);
                        self.loadingTable = false;
                        self.currentTablePage = response_data.data.pagination.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.data.message,
                        });
                    }

                    console.log(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getAsesor(url = process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-informasi/count-asesor") {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: url,
                headers: {
                    Accept: "application/json",
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    if (response_data.meta.code == 200) {
                        self.berlaku = response_data.data.berlaku;
                        self.tidak_berlaku = response_data.data.tidak_berlaku;
                        self.countBerlaku = self.berlaku.length;
                        self.countTidakBerlaku = self.tidak_berlaku.length;
                        console.log(self.countBerlaku);
                        console.log(self.countTidakBerlaku);
                        self.HasilAsesor.find((item) => item.nama == "Asesor Aktif").jumlah = self.countBerlaku;
                        self.HasilAsesor.find((item) => item.nama == "Asesor Non Aktif").jumlah = self.countTidakBerlaku;
                        self.jumlahAsesor.series = [self.countBerlaku, self.countTidakBerlaku];
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.data.message,
                        });
                    }

                    console.log(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getJenisTUk(url = process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-informasi/jenis-tuk") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;

            var config = {
                method: "get",
                url: url,
                params: {},
                headers: {
                    Accept: "application/json",
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    if (response_data.meta.code == 200) {
                        self.table_dataJenisTuk = response_data.data;
                        // self.jumlahTuk.series = response_data.data.map((item) => item.jml_tuk);
                        // self.jumlahTuk.chartOptions.labels = response_data.data.map((item) => item.jenis_nama);
                        // console.log(self.jumlahTuk.series);
                        // console.log(self.jumlahTuk.chartOptions.labels);

                        self.loadingTableJenisTuk = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.data.message,
                        });
                    }

                    console.log(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        toPage: function (str) {
            let self = this;
            self.getSkema(str);
        },
        configPagination(data) {
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
        getPelaksanaanAsesmen() {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-skema/pelaksanaan-asesmen",
                params: {},
                headers: {
                    Accept: "application/json",
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    if (response_data.meta.code == 200) {
                        self.JumlahPelaksaanAsesmen = response_data.data.data[0];
                        console.log(self.JumlahPelaksaanAsesmen);
                        self.HasilPelaksanaAsesmen.find((item) => item.kategori == "Biological Product").jumlah = self.JumlahPelaksaanAsesmen.count;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.data.message,
                        });
                    }

                    console.log(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<template>
    <Layout>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-img" style="background-image: url('/website/gedung-bio-farma.jpeg'); height: 75vh">
                        <div class="layer-light">
                            <h1 class="middle-center text-center text-white">INFORMASI</h1>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <div class="bg-grey-cust">
            <div class="container mt-3 py-1">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <b-tabs pills justified content-class="py-3 text-muted">
                                    <b-tab active class="border-0">
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="fas fa-home"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">Jumlah Asesor</span>
                                        </template>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                                    <thead class="bg-dark text-center text-white">
                                                        <tr>
                                                            <th>No</th>
                                                            <th>Kategori</th>
                                                            <th>Jumlah Asesor</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="text-center">
                                                        <tr v-for="(item, index) in HasilAsesor" :key="index">
                                                            <td>{{ index + 1 }}</td>
                                                            <td>{{ item.nama }}</td>
                                                            <td>{{ item.jumlah }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-md-4 mx-auto">
                                                <apexchart class="apex-charts" height="320" type="pie" dir="ltr" :series="jumlahAsesor.series" :options="jumlahAsesor.chartOptions"></apexchart>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="far fa-user"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">Jumlah Pelaksanaan Asesmen</span>
                                        </template>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                                    <thead class="bg-dark text-center text-white">
                                                        <tr>
                                                            <th>No</th>
                                                            <th>Kategori</th>
                                                            <!-- <th>sub Kategori</th> -->
                                                            <th>Jumlah Pelaksanaan Asesmen</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="text-center">
                                                        <tr v-for="(item, index) in HasilPelaksanaAsesmen" :key="index">
                                                            <td>{{ index + 1 }}</td>
                                                            <td>{{ item.kategori }}</td>
                                                            <!-- <td>{{ item.subkategori }}</td> -->
                                                            <td>{{ item.jumlah }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-md-12 text-center mt-3">
                                                <apexchart class="apex-charts" height="320" type="bar" dir="ltr" :series="jumlahPelaksanaAsesmen.series" :options="jumlahPelaksanaAsesmen.chartOptions"></apexchart>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="far fa-user"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block" @click="getJenisTUk()">Jumlah TUK</span>
                                        </template>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <datatable :columns="columnsJenisTuk">
                                                    <tbody>
                                                        <tr v-if="loadingTableJenisTuk">
                                                            <td colspan="10"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                        </tr>
                                                        <tr v-else-if="table_dataJenisTuk == ''">
                                                            <td class="text-center" colspan="10">Data Tidak Tersedia</td>
                                                        </tr>
                                                        <tr v-else v-for="(row_data, key_data) in table_dataJenisTuk" :key="key_data">
                                                            <td class="text-center">
                                                                {{ key_data + 1 }}
                                                            </td>
                                                            <td class="text-center">{{ row_data.jenis_nama }}</td>
                                                            <td class="text-center">{{ row_data.jml_tuk == null ? 0 : row_data.jml_tuk }}</td>
                                                        </tr>
                                                    </tbody>
                                                </datatable>
                                            </div>
                                            <div class="col-md-4 mx-auto mt-3">
                                                <apexchart class="apex-charts" height="320" type="pie" dir="ltr" :series="jumlahTuk.series" :options="jumlahTuk.chartOptions"></apexchart>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="far fa-user"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block" @click="getSkema()">Jumlah Skema</span>
                                        </template>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row mb-4">
                                                    <div class="col-md-4">
                                                        <table>
                                                            <tr>
                                                                <td>Show</td>
                                                                <td>
                                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getSkema()">
                                                                        <option value="10" selected>10</option>
                                                                        <option value="50">50</option>
                                                                        <option value="100">100</option>
                                                                        <option value="200">200</option>
                                                                        <option value="500">500</option>
                                                                    </select>
                                                                </td>
                                                                <td>Entries</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-md-5">&nbsp;</div>
                                                    <div class="col-md-3">
                                                        <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getSkema()" />
                                                    </div>
                                                </div>
                                                <datatable :columns="columnsSkema" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                                                    <tbody>
                                                        <tr v-if="loadingTable">
                                                            <td colspan="10"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                        </tr>
                                                        <tr v-else-if="table_dataSkema == ''">
                                                            <td class="text-center" colspan="10">Data Tidak Tersedia</td>
                                                        </tr>
                                                        <tr v-else v-for="(row_data, key_data) in table_dataSkema" :key="key_data">
                                                            <td class="text-center">
                                                                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                                                                <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
                                                            </td>
                                                            <td class="text-center">{{ row_data.kode_skema_sertifikasi }}</td>
                                                            <td class="text-center">{{ row_data.nama_skema_sertifikasi }}</td>
                                                            <td class="text-center">{{ row_data.jumlah_uk == null ? 0 : row_data.jumlah_uk }}</td>
                                                        </tr>
                                                    </tbody>
                                                </datatable>
                                                <pagination :pagination="pagination" @to-page="toPage"></pagination>
                                            </div>
                                            <!-- <div class="col-md-4">
                                                <apexchart class="apex-charts" height="320" type="pie" dir="ltr"
                                                    :series="pieChart.series" :options="pieChart.chartOptions"></apexchart>
                                            </div> -->
                                        </div>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="far fa-user"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">Persyaratan Dasar</span>
                                        </template>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                                    <thead class="bg-dark text-center text-white">
                                                        <tr>
                                                            
                                                            <th>No</th>
                                                            <th style="width: 20%;">Jabatan</th>
                                                            <th>Daftar Persyaratan</th>
                                                            <th>Biaya pendaftaran</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in meta_content" :key="index">
                                                            <td>{{ index + 1 }}</td>
                                                            <td class="text-center">{{ item.id.jabatan_nama }}</td>
                                                            <td><div v-html="item.persyataran"></div></td>
                                                            <td class="text-center">{{ item.biaya_pendaftaran }}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- <div class="col-md-4">
                                                <apexchart class="apex-charts" height="320" type="pie" dir="ltr"
                                                    :series="pieChart.series" :options="pieChart.chartOptions"></apexchart>
                                            </div> -->
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col -->
            </div>
        </div>

        <!-- end row -->
    </Layout>
</template>
